import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <ul>
        <li><a href="https://www.linkedin.com/in/marko-%C5%A1olaja-71810b290/">LinkedIn</a></li>
        <li><a href="https://www.instagram.com/marko.solajaa/">Instagram</a></li>
        <li><a href="https://github.com/soki02">GitHub</a></li>
      </ul>
    </section>
  );
};

export default Contact;
