import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Contact from './components/Contact';
import './styles.css';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Home />
      <Projects />
      <Blog />
      <Contact />
    </Router>
  );
};

export default App;

