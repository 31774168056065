import React from 'react';

const Projects = () => {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <p>No projects currently, but stay tuned!</p>
    </section>
  );
};

export default Projects;
