import React from 'react';

const Blog = () => {
  return (
    <section id="blog">
      <h2>Blog</h2>
      <p>No blog posts yet, but stay tuned!</p>
    </section>
  );
};

export default Blog;
